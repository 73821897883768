<!--
 * @Author: tjessie
 * @Date: 2022-02-10 13:36:09
 * @LastEditors: tjessie
 * @LastEditTime: 2022-02-10 13:36:36
 * @Description: file content
 * @FilePath: \juqu-skypegmwcn\src\views\pc\examine.vue
-->
<template>
	<div>
		<div class="content">
			<div class="top_details bg">
				<div class="top_details_title_out">
					<div class="top_details_title">
						{{ value.title }}
						<span class="top_details_price">
							{{ getTypeName(value.salary, 'salary') }}
						</span>
					</div>
					<div>联系电话：{{ value.tell }}</div>
				</div>
				<div class="top_details_des">
					长沙
					<span class="top_details_time">
						{{ getTypeName(value.indate, 'indate') }}</span
					>
				</div>
			</div>
			<div class="details bg">
				<div class="details_company">
					{{ value.companyName }}
				</div>
				<div class="details_title">
					岗位职责：
				</div>
				<div class="details_des">
					{{ value.responsibility }}
				</div>
				<div class="details_title">
					任职要求：
				</div>
				<div class="details_des">
					{{ value.jobRequire }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			value: {},
			teacherTypeList: [
				{
					id: 0,
					typeName: '线上老师',
				},
				{
					id: 1,
					typeName: '线下老师',
				},
				{
					id: 2,
					typeName: '辅导老师',
				},
			],
			regionTypeList: [
				{
					id: 1,
					typeName: '长沙',
				},
			],
			validityTypeList: [
				{
					id: 0,
					typeName: '长期有效',
				},
				{
					id: 1,
					typeName: '一周',
				},
				{
					id: 2,
					typeName: '一月',
				},
				{
					id: 3,
					typeName: '三月',
				},
				{
					id: 4,
					typeName: '半年',
				},
			],
			salaryTypeList: [
				{
					id: 0,
					typeName: '面议',
				},
				// {
				// 	id: 1,
				// 	typeName: '2000以下',
				// },
				{
					id: 1,
					typeName: '2000-4000',
				},
				{
					id: 2,
					typeName: '4000-8000',
				},
				{
					id: 3,
					typeName: '8000-12000',
				},
				{
					id: 4,
					typeName: '12000以上',
				},
			],
			educationTypeList: [
				{
					id: 0,
					typeName: '不限',
				},
				{
					id: 1,
					typeName: '初中及以下',
				},
				{
					id: 2,
					typeName: '高中、中专',
				},
				{
					id: 3,
					typeName: '大专',
				},
				{
					id: 4,
					typeName: '本科',
				},
				{
					id: 5,
					typeName: '硕士',
				},
				{
					id: 6,
					typeName: '博士及以上',
				},
			],
		}
	},
	created: function() {
		this.value = this.$route.query.value
		console.log(this.value)
	},
	methods: {
		getTypeName(id, type) {
			var options = []
			switch (type) {
				case 'salary':
					options = this.salaryTypeList
					break
				case 'education':
					options = this.educationTypeList
					break
				case 'issueType':
					options = this.teacherTypeList
					break
				case 'indate':
					options = this.validityTypeList
					break
			}
			// if (!id) {
			// 	return null
			// }
			// data为集合
			for (let i = 0, len = options.length; i < len; i++) {
				let item = options[i]
				if (item.id == id) {
					console.log(item.typeName)
					return item.typeName
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.content {
	width: 1200px;
	margin: 43px auto 50px;
	.top_details {
		height: 101px;
		margin: 0 0 16px 0;
		.top_details_title_out {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.top_details_title {
				font-weight: bold;
			}
			.top_details_price {
				font-weight: bold;
				color: #ff293e;
				margin-left: 5px;
			}
		}
		.top_details_des {
			padding-top: 16px;
			.top_details_time {
				padding-left: 23px;
				color: #3459ec;
			}
		}
	}
	.details {
		height: 806px;
		.details_company {
			font-weight: bold;
		}
		.details_title {
			padding: 18px 0 10px;
		}
	}
	.bg {
		width: 100%;
		background: #f8f8f8;
		font-size: 18px;
		color: #333333;
		padding: 16px 30px 0;
		box-sizing: border-box;
	}
}
</style>
